const styleSheets = {
  ".hotel-royal-level": "/etc.clientlibs/barcelo/clientlibs/clientlib-hotelRoyalLevel.css",
  ".c-popover": "/etc.clientlibs/barcelo/clientlibs/clientlib-popover.css",
  ".c-hightlights-services-banner": "/etc.clientlibs/barcelo/clientlibs/clientlib-hightlightServiceBanner.css",
  ".c-hotel-card": "/etc.clientlibs/barcelo/clientlibs/clientlib-hotelCard.css",
  ".mod--horizontal-card-compact": "/etc.clientlibs/barcelo/clientlibs/clientlib-hotelHorizontalCompact.css",
  ".c-meeting__table, .c-meeting__modal, .meeting__table": "/etc.clientlibs/barcelo/clientlibs/clientlib-meetingTable.css",
  ".bhg-loading, .loading-dots": "/etc.clientlibs/barcelo/clientlibs/clientlib-loadings.css",
  ".c-flip-card": "/etc.clientlibs/barcelo/clientlibs/clientlib-flipCard.css",
  ".c-card-hotel, .c-card-hotel-list, .c-label--tag-all-included": "/etc.clientlibs/barcelo/clientlibs/clientlib-cardHotel.css",
  ".cmp-breadcrumb, .breadcrumb": "/etc.clientlibs/barcelo/clientlibs/clientlib-breadcrumb.css",
  ".c-textimage, .textimage": "/etc.clientlibs/barcelo/clientlibs/clientlib-textImage.css",
  ".c-label": "/etc.clientlibs/barcelo/clientlibs/clientlib-label.css",
  ".c-submenu-container": "/etc.clientlibs/barcelo/clientlibs/clientlib-submenuContainer.css",
  ".c-card-hotel-list__content": "/etc.clientlibs/barcelo/clientlibs/clientlib-cardHotelList.css",
  ".info-service__wrapper, .c-info-service": "/etc.clientlibs/barcelo/clientlibs/clientlib-infoServicePage.css",
  ".c-v-menu": "/etc.clientlibs/barcelo/clientlibs/clientlib-vMenu.css",
  ".meetings-comparator": "/etc.clientlibs/barcelo/clientlibs/clientlib-cardHotelCompactMeeting.css",
  ".offer-collection-list, .meetings-comparator": "/etc.clientlibs/barcelo/clientlibs/clientlib-offerCollectionList.css",
  ".c-collection-list": "/etc.clientlibs/barcelo/clientlibs/clientlib-collectionList.css",
  ".footer, .xpf_reference_footer": "/etc.clientlibs/barcelo/clientlibs/clientlib-footer.css",
};

const loadedStyles = new Set();

const loadDynamicStyles = () => {
  Object.entries(styleSheets).forEach(([className, styleSheet]) => {
    if (!loadedStyles.has(className) && document.querySelector(className)) {
      bcl.u.loadStyleSheet(styleSheet, className);
      loadedStyles.add(className);
    }
  });
};

const observeDOMChanges = () => {
  const observer = new MutationObserver(loadDynamicStyles);
  observer.observe(document.body, { childList: true, subtree: true });
};

const checkDynamicComponents = () => {
  loadDynamicStyles();
  observeDOMChanges();
};

export { checkDynamicComponents };
