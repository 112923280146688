/* eslint-disable camelcase */
const fastbooking = {
  props: {
    dynamicClass: ".c-fastbooking.dynamicLoading",
    cssSelector: "c-fastbooking",
    url0: null,
    urlBE: null,
    data: null,
    utagPending: null,
    customId: {
      homepage: "_home",
      hotel: "_hotel",
      "campaigns-listing": "_campaign_listing",
      "hotel-listing": "_hotel_listing",
      "step-zero": "_step_zero",
    },
    attributes: {
      localStorage: "barcelo_last_searches",
    },
    campaigns: {
      id: "campaignId",
      templateGroup: "campaign-group",
      templateListing: "campaigns-listing",
      type: "campaignType",
      group: "group",
      listing: "listing",
    },
    activeFlight: false,
    domain: "bcl.com",
    redirectToBEDirectly: false,
    parentFb: null,
    buttonText: null,
    buttonSearch: null,
    buttonBRH: null,
    firstLoad: true,
    deeplinkingParams: {
      startDate: null,
      endDate: null,
    },
    ceresCookie: "bhg_ceres_it",
    ceresParam: "bhg_ceres_it",
    bookingEngineTypeCookie: "be_redirect",
    bookingEngineTypeParam: "be_redirect",
  },

  init: function () {
    bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-fastbooking.css", this.props.dynamicClass);

    if (bcl.editMode) {
      return;
    }
    const $elements = document.getElementsByClassName(this.props.cssSelector);

    if (!$elements || !$elements[0]) {
      return;
    }

    const index = this.checkPage();
    this.checkElements($elements, index);

    if (!$elements[0]) {
      return;
    }

    const $containerFBHero = document.querySelector(".container-fastbooking-hero-JS");

    if ($containerFBHero && $containerFBHero.dataset.fbcristal) {
      bcl.u.addClass($elements[0], $containerFBHero.dataset.fbcristal);
      $containerFBHero.dataset.fbbuttoncristal && this.setButtonColor($elements[0], $containerFBHero);
    }

    bcl.c.fastbooking.commonProps.fastbooking.$container = $elements[0];
    bcl.c.fastbooking.commonProps.fastbooking.url = bcl.c.fastbooking.commonProps.fastbooking.$container.dataset.fburl;
    bcl.c.fastbooking.commonProps.fastbooking.orderType = bcl.c.fastbooking.commonProps.fastbooking.$container.dataset.orderType ? bcl.c.fastbooking.commonProps.fastbooking.$container.dataset.orderType : "ranking";

    //------------------ Check if the components are available ------------------//

    const components = ["calendar", "destination", "discount", "rooms"];

    // Iterate through each component and check if it's available
    components.forEach((component) => {
      if (!bcl.c.fastbooking[component]) {
        throw new Error(`The component '${component}' is not available. Retrying...`);
      }
    });

    //---------------------------------------------------------------------------//

    this.afterInit();
    this.initButton();
    bcl.c.fastbooking.rooms.init();

    this.initHotelButton();

    this.initUrl();
    this.initAddFlight();
    this.initBookings();

    this.setEventUpdateRooms();

    bcl.c.fastbooking.commonProps.fastbooking.enabledDots = $elements[0].dataset.enableddots ? $elements[0].dataset.enableddots : false;
    bcl.c.fastbooking.commonProps.fastbooking.disabledPrices = $elements[0].dataset.disabledprices ? $elements[0].dataset.disabledprices : false;
    bcl.c.fastbooking.commonProps.fastbooking.enabledRounded = $elements[0].dataset.enabledRounded;

    bcl.u.resize(this.resize.bind(this));
    bcl.c.fastbooking.initDeeplinkingParams();

    if (document.querySelector(".mod--enabledrounded")) {
      this.designListener();
    }
  },

  showCalendar: function () {
    this.executeTriggerEvent();
  },

  closeCalendar: function () {
    bcl.s.tabs.closeActive();
  },

  setButtonColor: function ($fb, $containerFBHero) {
    bcl.u.addClass($fb, $containerFBHero.dataset.fbbuttoncristal);
  },

  setEventUpdateRooms: function () {
    const $inputAdults = bcl.c.fastbooking.commonProps.fastbooking.$container.querySelector(".input-adult-JS"),
      $inputDateEndBooking = bcl.c.fastbooking.commonProps.fastbooking.$container.querySelector("#checkout-fb");

    if (bcl.c.fastbooking.commonProps.calendar.updateOnlyCalendar) {
      bcl.c.fastbooking.commonProps.calendar.updateOnlyCalendar = false;
    }

    if (!$inputAdults && !$inputDateEndBooking) {
      return;
    }
  },

  checkElements: function ($elements, index) {
    if (!index) {
      index = 0;
    }

    for (let i = $elements.length - 1; i > index; i--) {
      const $parent = $elements[i].closest(".web-inherited-reference");

      $parent.parentNode.removeChild($parent);
    }
  },

  clickButtonBooking: function () {
    const $button = bcl.c.fastbooking.commonProps.fastbooking.$container.querySelector("." + bcl.c.fastbooking.commonProps.fastbooking.selectors.buttonBooking);
    bcl.u.triggerEvent($button, "click");
  },

  checkPage: function () {
    let $node;

    // Royal Hideaway
    if (bcl.u.containsClass(document.body, "brh-theme")) {
      $node = document.querySelector(".brh-header__fastbooking.brh-fastbooking");

      if (!$node || !$node.dataset.hotel) {
        return;
      }

      bcl.c.fastbooking.commonProps.fastbooking.isHotel = $node.dataset.hotel;
      this.buttonBRH = $node;
      return;
    }

    if (bcl.u.containsClass(document.body, "hotel")) {
      $node = document.querySelector("." + bcl.c.fastbooking.commonProps.fastbooking.selectors.hotelButton);

      if (!$node || !$node.dataset.hotel) {
        return;
      }

      bcl.c.fastbooking.commonProps.fastbooking.isHotel = $node.dataset.hotel;
      return;
    }

    if (bcl.u.containsClass(document.body, "page-detail")) {
      $node = document.querySelector(".c-header-detail__booking ." + bcl.c.fastbooking.commonProps.fastbooking.selectors.buttonRoom);

      const $newServiceNode = document.querySelector(".mod--new-services-JS");
      if ($newServiceNode) {
        return;
      }
      if ((!$node || !$node.dataset.hotel) && (!$newServiceNode || !$newServiceNode.dataset.hotelId)) {
        return -1;
      }

      const $newMeetingNode = document.querySelector(".mod--new-meeting-JS");
      if ((!$node || !$node.dataset.hotel) && (!$newMeetingNode || !$newMeetingNode.dataset.hotelId)) {
        return -1;
      }

      bcl.c.fastbooking.commonProps.fastbooking.isHotel = $node?.dataset.hotel || $newServiceNode?.dataset.hotelId;
    }
  },

  initUrl: function () {
    this.props.url0 = bcl.c.fastbooking.commonProps.fastbooking.$container.dataset.stepzero;
    this.props.urlBE = bcl.c.fastbooking.commonProps.fastbooking.$container.dataset.urlwrapperredirector;

    bcl.c.fastbooking.commonProps.fastbooking.url = bcl.c.fastbooking.commonProps.fastbooking.url.replace("json", "deeplink.json");
  },

  checkIfDisabledPrices: function ($button) {
    const $fastBooking = $button.closest("." + this.props.cssSelector);
    bcl.c.fastbooking.commonProps.fastbooking.disabledPrices = $fastBooking.dataset.disabledprices ? $fastBooking.dataset.disabledprices : false;
  },

  initButton: function () {
    this.initSearchButton();
    this.initBookingButton();
    this.initOpenButton();
    this.initCloseButton();
  },
  initHotelButton: function () {
    const $button = this.buttonBRH ? this.buttonBRH : document.querySelector("." + bcl.c.fastbooking.commonProps.fastbooking.selectors.hotelButton);
    if ($button) {
      bcl.c.fastbooking.commonProps.destination.used = true;
      this.props.noSend = true;
      this.clickBooking($button, true);
    }
  },
  initSearchButton: function () {
    const $button = bcl.c.fastbooking.commonProps.fastbooking.$container.querySelector("." + bcl.c.fastbooking.commonProps.fastbooking.selectors.buttonSearch);
    if ($button) {
      $button.addEventListener("click", (e) => {
        if (this.checkDestination(null, e.currentTarget)) {
          bcl.s.tabs.closeActive();
          this.requestGroup();
        }
      });

      this.props.buttons = {
        search: $button,
      };
    }

    this.chooseButtonId($button);
  },

  initBookingButton: function () {
    const $button = bcl.c.fastbooking.commonProps.fastbooking.$container.querySelector("." + bcl.c.fastbooking.commonProps.fastbooking.selectors.buttonBooking);
    if ($button) {
      const $fastBooking = $button.closest("." + this.props.cssSelector);
      this.props.redirectToBEDirectly = $fastBooking.dataset.redirecttobedirectly;
      $button.addEventListener("click", (e) => {
        if (!bcl.c.fastbooking.rooms.validateChildsSelects()) {
          bcl.u.triggerEvent(document.querySelector(".rooms-JS"), "click");
          return;
        } else {
          if (this.checkDestination(null, e.currentTarget)) {
            bcl.s.tabs.closeActive();
            this.checkIfDisabledPrices(e.currentTarget);
            bcl.c.fastbooking.calendar.finderOfDisponibilityToCurrentCheckDays();
            this.requestHotel();
          }
        }
      });

      this.props.buttons.booking = $button;
    }

    this.chooseButtonId($button);
  },

  initOpenButton: function () {
    const $button = document.querySelector("." + bcl.c.fastbooking.commonProps.fastbooking.selectors.open);

    if ($button) {
      $button.addEventListener("click", () => {
        this.openMobile();
      });
    }
  },

  initCloseButton: function () {
    const $button = document.querySelector("." + bcl.c.fastbooking.commonProps.fastbooking.selectors.close);

    if ($button) {
      $button.addEventListener("click", () => {
        this.closeMobile();
      });
    }
  },

  chooseButtonId: function ($currentButton) {
    const $button = $currentButton.querySelector(".c-cta");
    if ($button) {
      const $fastBooking = bcl.u.closest($button, bcl.c.fastbooking.commonProps.fastbooking.selectors.fastBookingNode);
      const template = ($fastBooking && $fastBooking.dataset.isstepzero) || document.documentElement.dataset.template;
      if (template) {
        $button.id = $button.id += this.props.customId[template] || "";
      }
    }
  },

  openMobile: function () {
    if (!bcl.u.mobile.isMobile() || bcl.u.containsClass(bcl.c.fastbooking.commonProps.fastbooking.$container, "mod--active")) {
      return;
    }

    bcl.s.tooltip.closeActive();

    bcl.u.addClass(bcl.c.fastbooking.commonProps.fastbooking.$container, "mod--active");
    bcl.u.addClass(window.$body, "activeFastbooking");
  },

  restoreFastbookingView: function () {
    bcl.u.removeClass(bcl.c.fastbooking.commonProps.fastbooking.$container, "mod--active");
    bcl.u.removeClass(window.$body, "activeFastbooking");
  },

  closeMobile: function () {
    if (!bcl.u.mobile.isMobile() || !bcl.u.containsClass(bcl.c.fastbooking.commonProps.fastbooking.$container, "mod--active")) {
      return;
    }

    bcl.s.tabs.closeActive();
    !bcl.u.mobile.iOS() && this.restoreFastbookingView();
    bcl.u.mobile.iOS() &&
      setTimeout(() => {
        this.restoreFastbookingView();
      }, 100);
  },

  changeButton: function (active) {
    if (active == "booking") {
      bcl.u.removeClass(this.props.buttons.booking, bcl.c.fastbooking.commonProps.fastbooking.hiddenClass);
      bcl.u.addClass(this.props.buttons.search, bcl.c.fastbooking.commonProps.fastbooking.hiddenClass);
    } else {
      bcl.u.addClass(this.props.buttons.booking, bcl.c.fastbooking.commonProps.fastbooking.hiddenClass);
      bcl.u.removeClass(this.props.buttons.search, bcl.c.fastbooking.commonProps.fastbooking.hiddenClass);
    }
  },

  afterInit: function () {
    bcl.c.fastbooking.calendar.init();
    bcl.c.fastbooking.destination.init();
    bcl.c.fastbooking.discount.init();
  },

  requestHotel: function (hotelCardList, offer) {
    if (!bcl.c.fastbooking.commonProps.destination.data) {
      bcl.u.triggerEvent(bcl.c.fastbooking.commonProps.destination.$destinationInput, "click");
      return;
    }

    if (bcl.c.fastbooking.commonProps.destination.data.type === "hotel" || this.isResort(event)) {
      this.saveDataInCookies();
      this.requestForHotel(hotelCardList, offer);

      // TODO Show spinner
      // Añadir clase loading
      const dateCheckin = bcl.c.fastbooking.commonProps.calendar.data.startDate;
      const dateCheckout = bcl.c.fastbooking.commonProps.calendar.data.endDate;

      if (dateCheckin && dateCheckout) {
        if (event.currentTarget.classList.contains(bcl.c.fastbooking.commonProps.fastbooking.selectors.buttonBooking)) {
          this.props.buttonSearch = event.currentTarget.children[0].innerText;
        } else {
          if (event?.currentTarget?.children[0]?.innerText) {
            this.props.buttonText = event.currentTarget.children[0].innerText;
          }
        }
        if (event) {
          event.currentTarget.children[0].innerText = "";
        }
        bcl.u.addSpinner(event.currentTarget);
      }
    }
  },

  requestGroup: function () {
    if (!bcl.c.fastbooking.commonProps.destination.data) {
      bcl.u.triggerEvent(bcl.c.fastbooking.commonProps.destination.$destinationInput, "click");
      return;
    }

    const checkDate = !!(bcl.c.fastbooking.commonProps.calendar.data.startDate && bcl.c.fastbooking.commonProps.calendar.data.endDate);

    this.saveDataInCookies();
    this.generateData();

    if (checkDate) {
      if (this.props.url0) {
        this.redirectZeroStep();
      }
    } else {
      this.requestForGroup();
    }
  },

  requestForGroup: function () {
    const data = {
      destinations: bcl.c.fastbooking.commonProps.destination.data.id,
    };
    if (bcl.c.fastbooking?.commonProps?.destination?.data?.label) {
      sessionStorage.setItem("destinationLabel", bcl.c.fastbooking.commonProps.destination.data.label.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
    }
    this.requestBE(data);
  },

  requestForFlight: function () {
    const data = {
      flight: true,
    };
    bcl.c.fastbooking.commonProps.destination.activeFlight = false;
    this.requestBE(data);
  },

  addDataInUrl: function (url, data) {
    let resultUrl = url;

    for (const key in data) {
      resultUrl += "&" + key + "=" + data[key];
    }

    return resultUrl;
  },

  requestForHotel: function (hotelCardList, offer) {
    const data = this.generateData();
    let action = "add_to_cart";

    if (!this.props.redirect && (!bcl.c.fastbooking.commonProps.fastbooking.isHotel || bcl.c.fastbooking.commonProps.fastbooking.isHotel != data.hotel)) {
      if (!this.isResort(event)) {
        delete data.arrive;
        delete data.depart;
      }

      if (!hotelCardList) {
        action = "select_product_content";
      }
    } else if (data.arrive && data.depart) {
      bcl.c.fastbooking.destination.loadLastSearch();
    }

    if (this.props.utagPending) {
      bcl.u.utag.push(this.props.utagPending);
      this.props.utagPending = null;
    } else if (bcl.u.utag.isEnabled() && !hotelCardList && !offer) {
      bcl.u.utag.push({
        ecommerce_action: "add",
        ecommerce_action_field_list_gtag: window.utag_data.ecommerce_action_field_list_gtag,
        ecommerce_action_gtag: action,
        product_id: [bcl.c.fastbooking.commonProps.destination.data.id],
        product_brand: [bcl.c.fastbooking.commonProps.destination.data.hotelBrand || bcl.c.fastbooking.commonProps.destination.data.data.hotelBrand],
        product_category: [bcl.c.fastbooking.commonProps.destination.data.hotelCategory || bcl.c.fastbooking.commonProps.destination.data.data.hotelCategory],
        product_name: [bcl.c.fastbooking.commonProps.destination.data.hotelName || bcl.c.fastbooking.commonProps.destination.data.data.hotelName],
        product_list: [window.utag_data.ecommerce_action_field_list_gtag],
        product_position: ["1"],
        event_name: "cta",
        event_category: "ecommerce",
        event_action: action,
        event_label: bcl.c.fastbooking.commonProps.destination.data.label,
        ga4_event_name: action,
      });
    }

    this.loadDataUtag();

    if ((data.arrive && data.depart && bcl.c.fastbooking.commonProps.calendar.disponibility) || !bcl.c.fastbooking.commonProps.fastbooking.isHotel || bcl.c.fastbooking.commonProps.fastbooking.isHotel != data.hotel) {
      this.requestBE(data, offer);
    }

    if ((!data.arrive || !data.depart || !bcl.c.fastbooking.commonProps.calendar.disponibility) && bcl.c.fastbooking.commonProps.fastbooking.isHotel === data.hotel) {
      this.afterSetData();
    }
  },

  requestBE: function (data, offer) {
    let url = this.props.urlBE;
    if (bcl.s?.translateOnTheFly?.hasToTranslate()) {
      url += bcl.s.translateOnTheFly.getTranslatedPageParameter();
    }
    if (data) {
      url = this.addDataInUrl(url, data);
    }

    url = this.addCustomDataInUrl(url, offer);

    bcl.ajax.getRequest(null, url, this.loadData);
  },

  addCustomDataInUrl: function (url, offer) {
    if (!url) {
      return;
    }

    const customData = { usercurrency: bcl.s.currency.props.currency.current },
      template = document.documentElement.dataset.template;
    if (template) {
      if (template.indexOf(bcl.c.fastbooking.props.campaigns.templateGroup) === 0) {
        customData[bcl.c.fastbooking.props.campaigns.type] = bcl.c.fastbooking.props.campaigns.group;
      }

      if (template.indexOf(bcl.c.fastbooking.props.campaigns.templateListing) === 0) {
        customData[bcl.c.fastbooking.props.campaigns.type] = bcl.c.fastbooking.props.campaigns.listing;
      }

      if (customData[bcl.c.fastbooking.props.campaigns.type] && offer && offer.campaign_id) {
        const campaignId = offer.campaign_id.substr(offer.campaign_id.lastIndexOf("/") + 1);
        if (campaignId) {
          customData[bcl.c.fastbooking.props.campaigns.id] = campaignId;
        }
      }
      //Add ceres selector for testing
      if (bcl.cookie.get(bcl.c.fastbooking.props.ceresCookie)) {
        customData[bcl.c.fastbooking.props.ceresParam] = "true";
      }

      if (bcl.cookie.get(bcl.c.fastbooking.props.bookingEngineTypeCookie)) {
        customData[bcl.c.fastbooking.props.bookingEngineTypeParam] = bcl.cookie.get(bcl.c.fastbooking.props.bookingEngineTypeCookie);
      }

      url = this.addDataInUrl(url, customData);
    }
    url = this.addDataInUrl(url, customData);

    return url;
  },

  loadData: function (res) {
    if (!res || !res.response) {
      return;
    }
    const data = JSON.parse(res.response);
    if (!data.Message) {
      if (bcl.c.fastbooking.commonProps.destination.data?.groupItems?.isResort && bcl.c.fastbooking?.props?.url0) {
        bcl.c.fastbooking.redirectZeroStep();
      }
      return;
    }

    if (bcl.profile.login == "true" && data.authenticate && data.isCeres) {
      data.Message = data.Message + "&logged=true";
    }
    if (bcl.profile.login == "true" && data.authenticate && !data.isCeres) {
      bcl.u.redirectForm(data, bcl.c.fastbooking.commonProps.fastbooking.$container);
    } else if (data.Success) {
      if (!data.newTab) {
        if (window.location.href.includes("/pinandtravel/")) {
          const $item = document.querySelector("." + bcl.c.fastbooking.commonProps.fastbooking.selectors.buttonBooking);
          bcl.u.removeSpinner($item);
          if (fastbooking.props.buttonSearch != null) {
            $item.children[0].innerText = fastbooking.props.buttonSearch;
          }
        }
        window.open(data.Message, "_self");
      } else {
        setTimeout(function () {
          // Quitar clase loading
          const items = document.getElementsByClassName(bcl.c.fastbooking.commonProps.fastbooking.selectors.buttonRoom);
          bcl.u.forEach(items, ($item) => {
            bcl.u.removeSpinner($item);
            if (fastbooking.props.buttonText != null) {
              $item.children[0].innerText = fastbooking.props.buttonText;
            }
          });
          const itemsPromo = document.getElementsByClassName(bcl.c.fastbooking.commonProps.fastbooking.selectors.buttonPromo);
          bcl.u.forEach(itemsPromo, ($item) => {
            bcl.u.removeSpinner($item);
            if (fastbooking.props.buttonText != null) {
              $item.children[0].innerText = fastbooking.props.buttonText;
            }
          });
          const $item = document.querySelector("." + bcl.c.fastbooking.commonProps.fastbooking.selectors.buttonBooking);
          bcl.u.removeSpinner($item);
          if (fastbooking.props.buttonSearch != null) {
            $item.children[0].innerText = fastbooking.props.buttonSearch;
          }
          bcl.u.goToNewTab(data);
        }, 1000);
      }
    } else {
      const shutDownModal = document.getElementById("be-shutdown-modal");
      if (data.ccShutDown) {
        const generalShutDownModal = document.getElementById("general-shutdown-modal");
        if (generalShutDownModal) {
          generalShutDownModal.click();
          return;
        }
      }
      if (shutDownModal) {
        shutDownModal.click();
      }
    }
  },

  generateData: function () {
    const data = {
        hotel: bcl.c.fastbooking.commonProps.destination.data.id,
      },
      isExternalBrandHotel = typeof bcl.c.fastbooking.commonProps.destination.data.isExternalBrandHotel == "undefined" ? false : bcl.c.fastbooking.commonProps.destination.data.isExternalBrandHotel;

    if (isExternalBrandHotel) {
      data.ext = true;
      this.props.utagData = bcl.u.deepExtend({}, data);
      return data;
    }

    if (bcl.c.fastbooking.commonProps.destination.activeFlight) {
      data.flight = true;
      bcl.c.fastbooking.commonProps.destination.activeFlight = false;
    }

    if (bcl.c.fastbooking.commonProps.destination.data.selector === "calendarroom") {
      data.roomcode = bcl.c.fastbooking.commonProps.destination.data.code;
    }

    data.coupon = [];
    data.promo = [];

    bcl.c.fastbooking.rooms.generateData(data);
    bcl.c.fastbooking.discount.generateData(data);

    if (bcl.c.fastbooking.commonProps.calendar.data.startDate && bcl.c.fastbooking.commonProps.calendar.data.endDate) {
      data.arrive = bcl.c.fastbooking.commonProps.calendar.data.startDate;
      data.depart = bcl.c.fastbooking.commonProps.calendar.data.endDate;

      this.saveSearch(data);
    }

    const today = bcl.u.getNormalizedDate(),
      tomorrow = bcl.u.getNormalizedDate();
    tomorrow.setDate(tomorrow.getDate() + 1);

    if (this.isCampaignGroup() || this.isResort(event)) {
      data.arrive = bcl.c.fastbooking.commonProps.calendar.data.startDate ? bcl.c.fastbooking.commonProps.calendar.data.startDate : bcl.u.formatDate(today);
      data.depart = bcl.c.fastbooking.commonProps.calendar.data.endDate ? bcl.c.fastbooking.commonProps.calendar.data.endDate : bcl.u.formatDate(tomorrow);
      this.props.redirect = this.props.redirectToBEDirectly;
    }

    this.props.utagData = bcl.u.deepExtend({}, data);

    const props = bcl.u.deepExtend({}, data);

    delete props.coupon;
    delete props.promo;
    delete props.discounts;

    const couponData = this.convertArrayToString(data.coupon);
    if (couponData) {
      props.coupon = couponData;
    }

    const campaignPromo = this.convertArrayToString(data.promo);
    if (campaignPromo) {
      props.promo = campaignPromo;
    }

    return props;
  },

  isCampaignGroup: function () {
    const isCampaignGroup = bcl.c.fastbooking.commonProps.discount.data.promo || this.props.redirectToBEDirectly;
    if (isCampaignGroup) {
      this.localStorageIfIsCampaign();
    }
    return isCampaignGroup;
  },

  isResort: function (event) {
    let resortButton = false;
    if (event) {
      resortButton = bcl.u.containsClass(event.target, "card-resort-JS") ? event.target : bcl.u.closest(event.target, "." + "card-resort-JS");
    }
    const resortBodyFlag = bcl.u.containsClass(document.body, "resorts-JS");
    return !!resortButton || resortBodyFlag;
  },

  localStorageIfIsCampaign: function () {
    sessionStorage.setItem("isCampaginGroup", window.location.href);
  },

  addCoupon: function (array, value) {
    array.push(value);
  },

  deleteValueInArray: function (array, valueToRemove) {
    return array.filter(function (value) {
      return value === valueToRemove;
    });
  },

  convertArrayToString: function (array) {
    const arrayCoupon = [];

    bcl.u.forEach(array, (element) => {
      if (bcl.c.fastbooking.commonProps.destination.data.data && bcl.c.fastbooking.commonProps.destination.data.data.specialDiscounts && bcl.c.fastbooking.commonProps.destination.data.data.specialDiscounts[element]) {
        this.addCoupon(arrayCoupon, bcl.c.fastbooking.commonProps.destination.data.data.specialDiscounts[element]);
      } else {
        this.addCoupon(arrayCoupon, element);
      }
    });

    return arrayCoupon.join(",");
  },

  deleteAllOtherValueInArray: function (array, valueToConserve) {
    return array.filter(function (value) {
      return value === valueToConserve;
    });
  },

  loadDataUtag: function () {
    const adults = bcl.c.fastbooking.commonProps.rooms.valueAdults,
      children = bcl.c.fastbooking.commonProps.rooms.valueChildren;
    let childages = bcl.c.fastbooking.props.utagData.childages;

    if (childages) {
      childages = childages.replace(/\|/gi, ",").replace(/n,?/gi, "");
    }

    const data = {
      search_type: "fastbooking",

      search_adults: "" + adults,

      search_children: "" + children,

      search_children_age: childages,

      search_coupon_name: this.props.utagData.promocode,

      search_special_discount: this.props.utagData.discounts,

      search_room_quantity: "" + this.props.utagData.rooms,

      search_total_guests: "" + (adults + children),
    };

    if (bcl.c.fastbooking.commonProps.destination.data.type !== "group") {
      data["search_hotel_id"] = bcl.c.fastbooking.commonProps.destination.data.id;
    }

    if (this.props.utagData.arrive && this.props.utagData.depart) {
      bcl.u.setDateInUtagData(data, this.props.utagData.arrive, this.props.utagData.depart);
    }

    if (bcl.u.utag.isEnabled()) {
      this.setCookies("barcelo_search_uatg_data", JSON.stringify(data));
    }
  },

  redirectZeroStep: function () {
    let url = this.props.url0,
      selector = "",
      noDispoParams = "";
    if (bcl.c.fastbooking.commonProps.destination.data?.groupItems?.isResort && bcl.c.fastbooking.commonProps.destination.data?.groupItems?.path) {
      window.location.href = bcl.c.fastbooking.commonProps.destination.data?.groupItems?.path;
      return;
    }
    if (bcl.c.fastbooking.commonProps.destination.data.type == "hotel") {
      selector += "rel-";
      noDispoParams += "&noDispo=true";
      noDispoParams += "&openingDate=" + bcl.c.fastbooking.commonProps.calendar.dataDest.data.openingDate;
    }
    selector += bcl.c.fastbooking.commonProps.destination.data.id;

    this.loadDataUtag();

    if (url.match(".html")) {
      const html = ".html";
      url = url.replace(html, "." + selector + html);
    } else {
      url = url.substring(0, url.length - 1);
      url += "--" + selector;
    }

    url += "?";

    if (bcl.u.containsClass(document.body, "isAuthor")) {
      url += "wcmmode=disabled&";
    }
    if (bcl.c.fastbooking.commonProps?.calendar?.data?.startDate) {
      url += "startDate=" + bcl.c.fastbooking.commonProps.calendar.data.startDate;
    }
    if (bcl.c.fastbooking.commonProps?.calendar?.data?.endDate) {
      url += "&endDate=" + bcl.c.fastbooking.commonProps.calendar.data.endDate;
    }
    if (bcl.c.fastbooking.commonProps?.destination?.data?.label) {
      url += "&title=" + bcl.c.fastbooking.commonProps.destination.data.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    url += noDispoParams;

    window.location.href = url;
  },

  redirectHotelsRelateds: function () {
    let url = this.props.url0;
    let selector = "";
    let noDispoParams = "";
    if (bcl.c.fastbooking.commonProps.destination.data.type == "hotel") {
      selector += "rel-";
      noDispoParams += "&noDispo=true";
      noDispoParams += "&openingDate=" + bcl.c.fastbooking.commonProps.calendar.dataDest.data.openingDate;
    }
    selector += bcl.c.fastbooking.commonProps.destination.data.id;

    if (url.match(".html")) {
      const html = ".html";
      url = url.replace(html, "." + selector + html);
    } else {
      url = url.substring(0, url.length - 1);
      url += "--" + selector;
    }

    url += "?";

    if (bcl.u.containsClass(document.body, "isAuthor")) {
      url += "wcmmode=disabled&";
    }

    if (bcl.c.fastbooking.commonProps.calendar.data.startDate) {
      url += "startDate=" + bcl.c.fastbooking.commonProps.calendar.data.startDate;
    }
    if (bcl.c.fastbooking.commonProps.calendar.data.endDate) {
      url += "&endDate=" + bcl.c.fastbooking.commonProps.calendar.data.endDate;
    }

    url += "&title=" + bcl.c.fastbooking.commonProps.destination.data.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    url += noDispoParams;
    window.location.href = url;
  },

  saveSearch: function (data) {
    bcl.c.fastbooking.commonProps.fastbooking.lastSearchData.push(this.generateDataLocalStorage(data));

    let length = bcl.c.fastbooking.commonProps.fastbooking.lastSearchData.length;

    while (length > 3) {
      bcl.c.fastbooking.commonProps.fastbooking.lastSearchData.shift();
      length--;
    }

    localStorage.setItem(bcl.c.fastbooking.commonProps.fastbooking.attributes.localStorage, JSON.stringify(bcl.c.fastbooking.commonProps.fastbooking.lastSearchData));
  },

  generateDataLocalStorage: function (data) {
    const dataSave = {
      destination: bcl.c.fastbooking.commonProps.destination.data,
      calendar: bcl.c.fastbooking.commonProps.calendar.data,
      discounts: bcl.c.fastbooking.commonProps.discount.data,
      rooms: {
        adults: bcl.c.fastbooking.commonProps.rooms.data.adults,
        children: bcl.c.fastbooking.commonProps.rooms.data.children,
        adultsCount: data.adult,
        childrenCount: data.child,
      },
    };

    return dataSave;
  },
  checkDestinationWasClicked: (item) => {
    bcl.c.fastbooking.commonProps.calendar.destinationWasClicked = bcl.c.fastbooking.destination.destinationWasClicked(item);
    if (bcl.c.fastbooking.commonProps.calendar.destinationWasClicked) {
      bcl.c.fastbooking.commonProps.calendar.obj.disabledDates = [];
    }
  },
  activeLastSearch: function (item) {
    this.checkDestinationWasClicked(item);
    bcl.c.fastbooking.calendar.updateInputs(item.calendar);
    bcl.c.fastbooking.destination.updateItem(item.destination);
    bcl.c.fastbooking.rooms.updateData(item.rooms);
    bcl.c.fastbooking.rooms.loadOccupancy();
    bcl.c.fastbooking.discount.updateData(item.discounts);
  },

  setData: function (data, noOpen) {
    bcl.c.fastbooking.commonProps.calendar.dataOffer = data.offer;

    bcl.c.fastbooking.destination.updateItem(data.destination);
    !noOpen && this.afterSetData();
  },

  executeTriggerEvent: function () {
    if (!this.isResort(event) || (this.isResort(event) && bcl.c.fastbooking.commonProps.destination.$destinationInput.value)) {
      bcl.u.triggerEvent(bcl.c.fastbooking.commonProps.calendar.$startDate, "focusin");
      bcl.c.fastbooking.commonProps.fastbooking.$container.scrollIntoView({ behavior: "smooth" });
    }
    if (this.isResort(event) && !bcl.c.fastbooking.commonProps.destination.$destinationInput.value) {
      bcl.u.triggerEvent(bcl.c.fastbooking.commonProps.destination.$destinationInput, "focusin");
      bcl.c.fastbooking.commonProps.fastbooking.$container.scrollIntoView({ behavior: "smooth" });
    }
  },

  afterSetData: function () {
    if (bcl.u.mobile.isMobile()) {
      this.openMobile();
      this.executeTriggerEvent();
    } else {
      bcl.u.goToWithScroll();
      this.executeTriggerEvent();
    }
  },

  updateFBDynamically: function () {
    if (bcl.c.fastbooking.commonProps.destination.data && bcl.c.hotelRoomList && !bcl.c.hotelRoomList.props.firstLoad) {
      if (bcl.c.fastbooking.commonProps.destination.data.id == bcl.c.hotelRoomList.props.hotelId) {
        bcl.c.hotelRoomList.updateCards();
      }
    }
  },

  initBookings: function (node) {
    if (!node) {
      node = document;
    }

    const items = node.getElementsByClassName(bcl.c.fastbooking.commonProps.fastbooking.selectors.buttonRoom);

    bcl.u.forEach(items, ($item) => {
      if ($item.dataset.load) {
        return;
      }

      $item.addEventListener("click", (e) => {
        e.stopPropagation();

        if ($item.closest(".brh-promo-list, .brh-hero-room-detail, .brh-room-detail, .brh-open-fastbooking-JS")) {
          document.dispatchEvent(new Event("brh-open-fastbooking"));
        }

        if (!bcl.c.fastbooking.rooms.validateChildsSelects() && !this.isNotOfferCards(e)) {
          bcl.u.triggerEvent(document.querySelector(".rooms-JS"), "click");
          bcl.u.goToWithScroll();
        } else {
          this.isCampaignGroup() && bcl.c.headerDetail.showFB();
          this.isResort(e) && bcl.c.headerDetail.showFB(e);
          this.clickBooking($item);
        }
      });

      $item.dataset.load = true;

      if ($item.children.length > 0) {
        $item.children[0].removeAttribute("href");
      }
    });
  },

  isNotOfferCards: function (e) {
    return bcl.u.findClosestNodeWithClass(e.currentTarget, "c-card-hotel-list");
  },

  clickBooking: function ($item, noOpen) {
    const specialDiscounts = {};

    if ($item.dataset.specialdiscount) {
      const array = $item.dataset.specialdiscount.split(",");
      bcl.u.forEach(array, function (element) {
        const dis = element.split("=");
        if (dis && dis.length == 2) {
          specialDiscounts[dis[0]] = dis[1];
        }
      });
    }

    const obj = {
      destination: {
        selector: $item.dataset.roomcode ? "calendarroom" : null,
        code: $item.dataset.roomcode,
        id: $item.dataset.hotel,
        data: {
          adultsOnly: $item.dataset.adultsonly,
          closingDate: $item.dataset.hotelclosing,
          openingDate: $item.dataset.hotelopening,
          minTeenagersAge: $item.dataset.minteenagersage,
          minAdultsAge: $item.dataset.maxagechild,
          hotelName: $item.dataset.hotelname,
          hotelCategory: $item.dataset.hotelcategory,
          hotelBrand: $item.dataset.hotelbrand,
          specialDiscounts: specialDiscounts,
          noDispo: $item.dataset.isnodispo ? JSON.parse($item.dataset.isnodispo) : $item.dataset.isnodispo,
          guestLimitTotal: $item.dataset.guestlimittotal,
          adultLimit: $item.dataset.adultlimit,
          childrenLimit: $item.dataset.childrenlimit,
        },
        label: $item.dataset.searchtitle,
        type: "hotel",
      },
      redirect: $item.dataset.redirect,
    };
    this.processBooking(obj, noOpen, $item);
    bcl.c.fastbooking.rooms.loadOccupancy();
  },
  bookingDaysNotExistsIntoHotelClosedDays: function (obj) {
    const startDate = bcl.c.fastbooking.commonProps.calendar.data.startDate,
      endDate = bcl.c.fastbooking.commonProps.calendar.data.endDate;

    if (startDate && endDate) {
      const startDay = new Date(startDate),
        endDay = new Date(endDate),
        openingDate = obj.destination.data.openingDate,
        closingDate = obj.destination.data.closingDate,
        openingDay = openingDate ? new Date(openingDate) : null,
        closingDay = closingDate ? new Date(closingDate) : null;
      let validDate = true;

      if (openingDay && !closingDay && (startDay || endDay) < openingDay) {
        validDate = false;
      }

      if (validDate && closingDay && (startDay || endDay) >= closingDay && (startDay || endDay) < openingDay) {
        validDate = false;
      }

      return validDate;
    }
    return false;
  },
  setValueAdultsToOne: function () {
    if (bcl.c.fastbooking?.commonProps?.rooms?.valueAdults != 1 && bcl?.c?.fastbooking?.props?.firstLoad) {
      bcl.c.fastbooking.props.firstLoad = false;
      bcl.c.fastbooking?.commonProps?.fastbooking?.$container?.querySelector(".dec-button-JS")?.click();
    }
  },
  processBooking: function (obj, noOpen, $item) {
    const isHotel = document.getElementsByClassName(bcl.c.fastbooking.commonProps.fastbooking.selectors.headHotelSelector);
    const isHotelCardList = $item && $item.dataset.hotelcardlist ? $item.dataset.hotelcardlist : null;
    const startDate = bcl.c.fastbooking.commonProps.calendar.data.startDate;
    const endDate = bcl.c.fastbooking.commonProps.calendar.data.endDate;

    if (obj.redirect && startDate && endDate && (!this.isResort(null) || (this.isResort(null) && obj.destination.id))) {
      noOpen = this.bookingDaysNotExistsIntoHotelClosedDays(obj);
    }
    if (obj?.offer?.campaignProperties?.dispoOneAdults) {
      this.setValueAdultsToOne();
    }
    this.setData(obj, noOpen);

    if ($item && isHotelCardList && isHotel.length == 0 && this.checkDestination(null, $item)) {
      bcl.s.tabs.closeActive();
      this.requestHotel(isHotelCardList);
    }

    if (((!bcl.profile.login || bcl.profile.login == "false") && noOpen) || (bcl.profile.login == "true" && noOpen && !isHotelCardList)) {
      bcl.s.tabs.closeActive();

      if (!this.props.noSend) {
        this.props.redirect = true;
        this.requestHotel(isHotelCardList, obj.offer);
        this.props.redirect = false;
      }

      this.props.noSend = false;
    }
  },

  initAddFlight: function () {
    const items = bcl.c.fastbooking.commonProps.fastbooking.$container.getElementsByClassName(bcl.c.fastbooking.commonProps.fastbooking.selectors.addFlight);

    bcl.u.forEach(items, ($item) => {
      $item.addEventListener("click", () => {
        bcl.c.fastbooking.commonProps.destination.activeFlight = true;

        if (!bcl.c.fastbooking.commonProps.destination.data || bcl.c.fastbooking.commonProps.destination.data.type != "hotel") {
          this.requestForFlight();
          return;
        }

        this.requestForHotel();
      });
    });
  },

  clickInput: function ($containerActive, object) {
    this.openMobile();
    return this.checkDestination($containerActive, object.$tab);
  },

  checkDestination: function ($containerActive, $tab) {
    let output = true;
    bcl.c.fastbooking.commonProps.destination.showTooltip = false;

    if (
      !bcl.c.fastbooking.commonProps.destination.data &&
      ($tab.id != "destination-fb" || bcl.u.containsClass($tab, bcl.c.fastbooking.commonProps.fastbooking.selectors.buttonSearch) || bcl.u.containsClass($tab, bcl.c.fastbooking.commonProps.fastbooking.selectors.buttonBooking))
    ) {
      const destinationTab = document.getElementById("destination-fb");
      if (destinationTab) {
        bcl.c.fastbooking.destination.closeSearch();
        bcl.c.fastbooking.destination.changeContainer("submenuDestination");
        destinationTab.focus();
        bcl.c.fastbooking.commonProps.destination.showTooltip = true;
        bcl.u.triggerEvent(destinationTab, "click");
      }
      output = false;
    } else if ($tab.id == "checkout-fb") {
      const $checkinTab = document.getElementById("checkin-fb");
      if ($checkinTab) {
        bcl.c.fastbooking.commonProps.calendar.obj.end = NaN;
        bcl.c.fastbooking.commonProps.calendar.obj.updateSelectableRange();
        $checkinTab.focus();
        bcl.u.triggerEvent($checkinTab, "focusin");
      }
      output = false;
    }
    if ($tab.id == "rooms-fb" || $tab.id == "discount-fb") {
      const $tabElement = document.getElementById($tab.id);
      $tabElement.blur();
    }

    return output;
  },

  saveDataInCookies: function () {
    bcl.c.fastbooking.calendar.addCookies();
    bcl.c.fastbooking.rooms.addCookies();
    bcl.c.fastbooking.discount.addCookies();
  },

  setCookies: function (cookieName, data) {
    const expired = new Date();
    expired.setHours(expired.getHours() + 24);

    bcl.cookie.set(cookieName, data, {
      expires: expired,
      path: "/" /*,
                domain: bcl.c.fastbooking.props.domain*/,
    });
  },

  resize: function () {
    if (bcl.editMode || !bcl.c.fastbooking.commonProps.fastbooking.$container) {
      return;
    }

    if (bcl.u.mobile.isMobile()) {
      return;
    }

    bcl.s.tabs.closeActive();

    bcl.c.fastbooking.closeMobile();

    bcl.c.fastbooking.calendar.resize();
  },
  initDeeplinkingParams: function () {
    bcl.c.fastbooking.parseQueryParams();
    if (!bcl.c.fastbooking.commonProps.calendar.data.startDate && !bcl.c.fastbooking.commonProps.calendar.data.endDate && (bcl.c.fastbooking.props.deeplinkingParams.startDate || bcl.c.fastbooking.props.deeplinkingParams.endDate)) {
      bcl.c.fastbooking.calendar.updateInputs(bcl.c.fastbooking.props.deeplinkingParams);
    }
  },
  parseQueryParams: function () {
    let values = window.location.search;
    const obj = {};

    if (!values) {
      return;
    }

    values = decodeURIComponent(values);
    values = values.substring(1, values.length).split("&");

    for (let i = 0; i < values.length; i++) {
      const item = values[i].split("=");

      if (item.length <= 1) {
        continue;
      }

      const key = item[0],
        value = item[1];

      if (key == "startDate" || key == "endDate") {
        bcl.c.fastbooking.props.deeplinkingParams[key] = value;
      } else if (key != "wcmmode") {
        if (!obj[item[0]]) {
          obj[item[0]] = {};
        }

        const valueSplit = value.split(",");

        for (let n = 0; n < valueSplit.length; n++) {
          obj[key][valueSplit[n]] = true;
        }
      }
    }
  },
  designListener: function () {
    const $container = document.querySelector(".datepicker__months");
    $container.addEventListener("click", function (e) {
      setTimeout(function () {
        const $lastDay = e.target.closest(".datepicker__month-day--last-day-selected");
        if ($lastDay) {
          $container.querySelector(".datepicker__month-day--first-day-selected")?.classList.add("first-day-range");
        }

        $container.querySelectorAll(".first-day-range").forEach(function ($firstDayRange) {
          if (!$firstDayRange.classList.contains("datepicker__month-day--first-day-selected") || !$container.querySelector(".datepicker__month-day--last-day-selected")) {
            $firstDayRange.classList.remove("first-day-range");
          }
        });
      }, 100);
    });
  },
};

export default fastbooking;
