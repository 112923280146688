import { BarceloComponent } from "barcelo-components";

const StructureComponents = {
  brands: BarceloComponent({ selector: ".button-top-footer-JS", path: "structure/brands/js/c-brands" }),
  megaMenu: BarceloComponent({
    selector: ".c-megamenu",
    path: "structure/header/js/megaMenu",
    skipInit: true,
    belongsTo: "header",
  }),
  asideMenu: BarceloComponent({
    selector: ".c-aside-menu",
    path: "structure/header/js/asideMenu",
    skipInit: true,
    belongsTo: "header",
  }),
  sticky: BarceloComponent({
    selector: ".c-header",
    path: "structure/header/js/sticky",
    skipInit: true,
    belongsTo: "header",
  }),
  language: BarceloComponent({
    selector: ".language-JS",
    path: "structure/language/js/c-language",
    skipInit: true,
    belongsTo: "header",
  }),
  phoneCC: BarceloComponent({
    selector: ".phoneCC",
    path: "structure/header/js/phoneCC",
    skipInit: true,
    belongsTo: "header",
  }),
  header: BarceloComponent({ selector: ".header", path: "structure/header/js/c-header" }),
  heroslider: BarceloComponent({ selector: ".c-heroslider", path: "structure/heroslider/js/c-heroslider" }),
  hotelMeetingRooms: BarceloComponent({ selector: ".c-hotel-meeting-rooms", path: "structure/hotel-meeting-rooms/js/c-hotel-meeting-rooms" }),
  hotelRoyal: BarceloComponent({ selector: ".hotel-royal", path: "structure/hotel-royal/js/hotel-royal-colors" }),
  calendar: BarceloComponent({
    selector: ".c-fastbooking__submenu-calendar",
    path: "structure/fastbooking/js/calendar",
    skipInit: true,
    belongsTo: "fastbooking",
  }),
  destination: BarceloComponent({
    selector: ".destination-JS",
    path: "structure/fastbooking/js/destination",
    skipInit: true,
    belongsTo: "fastbooking",
  }),
  discount: BarceloComponent({
    selector: ".c-fastbooking__submenu-discount",
    path: "structure/fastbooking/js/discount",
    skipInit: true,
    belongsTo: "fastbooking",
  }),
  awardsSwiper: BarceloComponent({ selector: ".c-awards-swipper", path: "structure/awards-swipper/js/awards-swipper" }),
  rooms: BarceloComponent({
    selector: ".c-fastbooking__submenu-rooms",
    path: "structure/fastbooking/js/rooms",
    skipInit: true,
    belongsTo: "fastbooking",
  }),
  highlightsGallery: BarceloComponent({ selector: ".c-highlights-swiper", path: "structure/highlights-gallery/js/highlights-gallery" }),
  fastbooking: BarceloComponent({ selector: ".c-fastbooking", path: "structure/fastbooking/js/c-fastbooking" }),
  customModal: BarceloComponent({
    selector: ".tingle-custom-modal-JS",
    path: "structure/custom-modal/js/custom-modal",
  }),
  headerDetail: BarceloComponent({
    selector: ".c-header-detail[data-hotel-id]",
    path: "structure/header-detail/js/c-header-detail",
  }),
  campaignGenericComponentGroup: BarceloComponent({
    selector: ".campaign-group-fb-JS",
    path: "structure/campaign-generic-group/js/c-campaign-generic-component-group",
  }),
  localeConfigurationForm: BarceloComponent({
    selector: ".locale-form-JS",
    path: "structure/locale-configuration-form/js/c-locale-configuration-form",
  }),
  footer: BarceloComponent({ selector: "footer", path: "structure/footer/js/footer" }),
};

export default StructureComponents;
